import { CardContent, CardHeader, CardTitle } from '@repo/ui/components/Card.js'
import { redirect } from 'react-router'
import { LoginButton } from '~/components/LoginButton'
import { authenticator } from '~/services/auth/auth.server'
import { actionResponse, validateFormData } from '~/utils/actions.server'
import { setCookie } from '~/utils/cookie.server'
import type { Route } from './+types/route'
import { LoginSchema } from './route.config'

export const action = async ({ request }: Route.ActionArgs) => {
  const submission = await validateFormData(request, LoginSchema)
  if (submission.status !== 'success')
    return actionResponse.error(submission.reply())
  const { url, codeVerifier } = await authenticator.generateAuthUrl(
    submission.value.provider
  )
  const headers = new Headers()
  headers.append(
    'set-cookie',
    await setCookie('timezone', submission.value.timezone)
  )
  headers.append(
    'set-cookie',
    await setCookie('authCodeVerifier', codeVerifier)
  )
  return redirect(url, { headers })
}

export default function LoginPage() {
  return (
    <>
      <CardHeader className="flex flex-col items-center justify-center gap-2">
        <CardTitle className="font-heading text-2xl tracking-normal">
          Log in or sign up
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <LoginButton provider="google" />
        <LoginButton provider="microsoft" />
        <small className="block w-full text-center text-muted-foreground">
          Please use your work email account.
        </small>
      </CardContent>
    </>
  )
}
